import React from 'react';
import { Panel, Spinner, Search, PullToRefresh } from '@vkontakte/vkui';
import Header from '../components/Header/Header';
import CategorieList from '../components/CategorieList/CategorieList';

//хелперы
import app from '../func/app';
import navigation from '../func/navigation';
import { dynamicSort } from '../func/utils';
import AdBlock from '../components/AdBlock/AdBlock';
import { getData } from '../func/back';

class Categorie extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			isLoad: false,
			topics: [],
			searchText: "",
			fetching: false,
		};
	}

	componentDidMount() {
		const { topics, target } = this.props
		this.setState({
			isLoad: true,
			topics: topics.filter(({categories}) => { return (categories.indexOf(target) !== -1) ? true : false })
		})
	}

	get items() {
		const { topics, searchText } = this.state
		return topics.sort(dynamicSort("sid")).filter(({name}) => { return (name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ? true : false })
	}

	onSearch = (text) => {
		this.setState({ searchText: text })
	}

	onRefresh = () => {
		this.setState({
			fetching: true
		}, () => {
			getData((data) => {
				const { categories, topics } = data	
				app.setState({
					categories:categories,
					topics:topics,
				}, () => {
					this.setState({
						fetching: false
					})
				})
			})
		})
	}

	render() {
		let { isLoad, topics, searchText, fetching } = this.state
		let { id } = this.props
		return (
			<Panel theme="white" id={id}>
				<Header noShadow={true} panelBack={ navigation.goBack } name="Темы"/>
				<PullToRefresh onRefresh={this.onRefresh} isFetching={fetching}>
					<div>
						<Search value={searchText} onChange={this.onSearch}/>
						{
							(isLoad) ?
								<div>
									<CategorieList
										noTopics={(topics.length > 0) ? false : true}
										items={this.items}
									/>
									<AdBlock/>
								</div>
							: <Spinner size="regular" style={{ marginTop: 20 }} />
						}
					</div>
				</PullToRefresh>
			</Panel>
		)
	}
}

export default Categorie;