import React from 'react';
import { Panel, Button, FormLayout, Input, Div } from '@vkontakte/vkui';
import Header from '../components/Header/Header';

//хелперы
import app from '../func/app';
import navigation from '../func/navigation';
import { findObjectIndex, findObjectById } from '../func/utils';
import { showConfirmationAlert } from '../func/alert';
import { delCategorie, editCategorie } from '../func/back';

class CategorieEdit extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		const { targetEdit, categories } = this.props
		const obj = findObjectById(categories, +targetEdit)
		if (obj) {
			this.setState({
				...obj
			})
		}
	}

	onRemove = () => {
		showConfirmationAlert('Подтвердите действие.', 'Вы действительно хотите удалить категорию?', () => {
			const { id } = this.state
			delCategorie(+id, () => {
				const { categories, targetEdit } = this.props
				const index = findObjectIndex(categories, targetEdit)
				if (index !== -1) {
					app.setState((state, props) => ({
						topics: [
							...state.topics.map((item) => {
								return {
									...item,
									categories: (item.categories.indexOf(state.categories[index].id) !== -1) ? 
										[
											...item.categories.slice(0, +item.categories.indexOf(state.categories[index].id)), 
											...item.categories.slice(+item.categories.indexOf(state.categories[index].id) + 1)
										] 
									: 
										[
											...item.categories
										]
								}
							})
						],
						categories: [...state.categories.slice(0, +index), ...state.categories.slice(+index + 1)]
					}), () => {
						navigation.goBack()
					})
				}
			})
		})
	}

	onChangeInput = (e) => {
		const { name, value } = e.currentTarget;
		this.setState({ 
			[name]: (name === "sid") ? +value : value 
		});
	}

	onSave = () => {
		const { id, sid, name } = this.state
		const { categories, targetEdit } = this.props
		const index = findObjectIndex(categories, targetEdit)
		if (index !== -1) {
			editCategorie(id, sid, name, () => {
				app.setState((state, props) => ({
					categories: [...state.categories.slice(0, +index), {
						id:id, 
						sid:sid,
						name:name,
					}, ...state.categories.slice(+index + 1)]
				}), () => {
					navigation.goBack()
				})
			})
		}
	}

	render() {
		let { sid, name } = this.state
		let { id } = this.props
		return (
			<Panel theme="white" id={id}>
				<Header panelBack={ navigation.goBack } name="CategorieEdit"/>
				<FormLayout>
					<Input onChange={this.onChangeInput} name="name" top="Название" type="text" value={String(name)} placeholder="Введите название категории" />
					<Input onChange={this.onChangeInput} name="sid" min="0" top="Сортировка" value={String(sid)} type="number" placeholder="Число для сортировки" />
				</FormLayout>
				<Div className="flex_end">
					<Button level="destructive" className="mr-5" onClick={this.onRemove}>Удалить</Button>
					<Button onClick={this.onSave}>Сохранить</Button>
				</Div>
			</Panel>
		)
	}

}

export default CategorieEdit;