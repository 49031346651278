import React from 'react';
import { Panel, CellButton, List, Cell } from '@vkontakte/vkui';
import Header from '../components/Header/Header';
import MenuTabs from '../components/MenuTabs/MenuTabs'
import MenuItem from '../components/MenuTabs/MenuItem'
import BadgeBlock from '../components/BadgeBlock/BadgeBlock';
//хелперы
import app from '../func/app';
import { dynamicSort, findObjectById } from '../func/utils';
import navigation from '../func/navigation';
import Icon24Add from '@vkontakte/icons/dist/24/add';

class Admin extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {};
	}

	get categories() {
		const { categories } = this.props
		return categories.sort(dynamicSort("sid"))
	}

	get topics() {
		const { topics } = this.props
		return topics.sort(dynamicSort("sid"))
	}

	getCategories = (arr) => {
		return (
			arr.map((catId, i) => {
				const oneCat = findObjectById(this.categories, catId)
				const { name } = oneCat
				return (
					<BadgeBlock key={i} className="mr-5 mt-5" color="#fff" backgroundColor="rgba(0,0,0,.6)">{name}</BadgeBlock>
				)
			})
		)
	}

	editCategorie = (e) => {
		const { cid } = e.currentTarget.dataset
		app.setState({
			targetEdit: +cid
		}, () => {
			navigation.go({activePanelComponents: 'CategorieEdit'})
		})
	}

	editTopic = (e) => {
		const { tid } = e.currentTarget.dataset
		app.setState({
			targetEdit: +tid
		}, () => {
			navigation.go({activePanelComponents: 'TopicEdit'})
		})
	}

	addTopic = (e) => {
		navigation.go({activePanelComponents: 'TopicAdd'})
	}

	addCategorie = (e) => {
		navigation.go({activePanelComponents: 'CategorieAdd'})
	}

	changeMenu = (id) => {
		app.setState({
			activeItem: id,
		})
	}

	render() {
		let { activeItem } = this.props
		let { id } = this.props
		return (
			<Panel theme="white" id={id}>
				<Header panelBack={ navigation.goBack } name="Admin"/>
				<MenuTabs changeMenu={this.changeMenu} activeItem={activeItem}>

					<MenuItem id="categories" title="Категории">
						<List>
							<CellButton onClick={this.addCategorie} before={<Icon24Add />}>Добавить категорию</CellButton>
							{
								this.categories.map((cat, index) => {
									const { name, id } = cat
									return (
										<Cell 
											data-cid={id} 
											key={id} 
											onClick={this.editCategorie}
											expandable={true}
										>
											{name}
										</Cell>
									)
								})
							}
						</List>
					</MenuItem>

					<MenuItem id="topics" title="Темы" >
						<List>
							<CellButton onClick={this.addTopic} before={<Icon24Add />}>Добавить тему</CellButton>
							{
								this.topics.map((topic, index) => {
									const { name, id, categories } = topic
									return (
										<Cell 
											data-tid={id}
											key={id}
											description={this.getCategories(categories)}
											onClick={this.editTopic}
											multiline={true}
											expandable
										>
											{name}
										</Cell>
									)
								})
							}
						</List>
					</MenuItem>

				</MenuTabs>


			</Panel>
		)
	}

}

export default Admin;