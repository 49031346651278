import React from 'react';
import { Panel, Button, FormLayout, Input, Div, Cell, List, FormLayoutGroup } from '@vkontakte/vkui';
import Header from '../components/Header/Header';

//хелперы
import app from '../func/app';
import navigation from '../func/navigation';
import { findObjectIndex } from '../func/utils';
import { showConfirmationAlert } from '../func/alert';
import { createTopic } from '../func/back';

import Icon24Done from '@vkontakte/icons/dist/24/done';

class TopicAdd extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			id:null,
			sid: 0,
			name: "",
			categories: [],
			link: "",
		}
	}

	onRemove = () => {
		showConfirmationAlert('Подтвердите действие.', 'Вы действительно хотите удалить тему?', () => {
			const { topics, targetEdit } = this.props
			const index = findObjectIndex(topics, targetEdit)
			if (index !== -1) {
				app.setState((state, props) => ({
					topics: [...state.topics.slice(0, +index), ...state.topics.slice(+index + 1)]
				}), () => {
					navigation.goBack()
				})
			}
		})
	}

	onSave = () => {
		const { sid, name, categories, link } = this.state
		createTopic(sid, name, categories, link, (response) => {
			const { id } = response
			app.setState((state, props) => ({
				topics: [...state.topics, {
					id:id,
					sid:sid,
					name:name,
					categories:categories,
					link:link
				}]
			}), () => {
				navigation.goBack()
			})	
		})
	}

	onChangeInput = (e) => {
		const { name, value } = e.currentTarget;
		this.setState({ 
			[name]: (name === "sid") ? +value : value 
		});
	}

	onCheckCat = (e) => {
		const { cid } = e.currentTarget.dataset;
		const { categories } = this.state
		
		if (categories.indexOf(+cid) !== -1) {
			let index = categories.indexOf(+cid)
			this.setState((state, props) => ({
				categories: [...state.categories.slice(0, index), ...state.categories.slice(index + 1)]
			}))
		} else {
			this.setState((state, props) => ({
				categories: [...state.categories, +cid]
			}))
		}
	}

	render() {
		let { sid, name, link } = this.state
		let topicCat = this.state.categories
		let { id, categories } = this.props
		return (
			<Panel theme="white" id={id}>
				<Header panelBack={ navigation.goBack } name="TopicAdd"/>
				
				<FormLayout>
					<Input onChange={this.onChangeInput} name="name" top="Название" type="text" value={String(name)} placeholder="Введите название темы" />
					<Input onChange={this.onChangeInput} name="link" top="Ссылка" value={String(link)} type="text" placeholder="Ссылка на тему" />
					<Input onChange={this.onChangeInput} name="sid" min="0" top="Сортировка" value={String(sid)} type="number" placeholder="Число для сортировки" />
					
					<FormLayoutGroup top="Категории">
						{
							(topicCat && categories) ?
								<List>
									{
										categories.map((cat) => {
											const { id, name } = cat
											return (
												<Cell 
													data-cid={id}
													key={id}
													asideContent={(topicCat.indexOf(id) !== -1) ? <Icon24Done fill="var(--accent)" /> : null}
													onClick={this.onCheckCat}
												>
													{name}
												</Cell>
											)
										})
									}
								</List>
							: null
						}
					</FormLayoutGroup>
				
				</FormLayout>
				<Div className="flex_end">
					<Button level="destructive" className="mr-5" onClick={this.onRemove}>Удалить</Button>
					<Button onClick={this.onSave}>Создать</Button>
				</Div>
			</Panel>
		)
	}

}

export default TopicAdd;