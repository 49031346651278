import React from 'react';
import { Panel, FormLayout, Input, Div, Button } from '@vkontakte/vkui';
import Header from '../components/Header/Header';

//хелперы
import app from '../func/app';
import navigation from '../func/navigation';
import { createCategorie } from '../func/back';

class CategorieAdd extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			sid: 0,
			name: ""
		};
	}
	
	onChangeInput = (e) => {
		const { name, value } = e.currentTarget;
		this.setState({ 
			[name]: (name === "sid") ? +value : value 
		});
	}

	onSave = () => {
		const { sid, name } = this.state
		createCategorie(sid, name, (response) => {
			const { id } = response
			const { sid, name } = this.state
			app.setState((state, props) => ({
				categories: [...state.categories, {id:id, sid:sid, name:name}]
			}), () => {
				navigation.goBack()
			})
		})
	}

	render() {
		let { sid, name } = this.state
		let { id } = this.props
		return (
			<Panel theme="white" id={id}>
				<Header panelBack={ navigation.goBack } name="CategorieAdd"/>
				<FormLayout>
					<Input onChange={this.onChangeInput} name="name" top="Название" type="text" value={String(name)} placeholder="Введите название категории" />
					<Input onChange={this.onChangeInput} name="sid" min="0" top="Сортировка" value={String(sid)} type="number" placeholder="Число для сортировки" />
				</FormLayout>
				<Div className="flex_end">
					<Button onClick={this.onSave}>Создать</Button>
				</Div>
			</Panel>
		)
	}

}

export default CategorieAdd;