import React from 'react';
import { Group, Div, List, Avatar, Cell } from '@vkontakte/vkui';
import './style.css';

import one from './images/44444.jpg'
import two from './images/3333.jpg'
import tree from './images/22222.jpg'

import banner from './images/Banner_3.jpg'

class AdBlock extends React.Component {
	render() {
		return (
			<Group title="Рекомендуем посмотреть:">
				<Div className="flex_center">
					<a rel="noopener noreferrer" target="_blank" href="https://calculatorxe.ru/">
						<img alt="" className="ImageBanner" src={banner} />
					</a>
				</Div>
				<List>
					<Cell 
						before={
							<Avatar type="app" src={one} />
						} 
						href="https://vk.com/@dnevnik.diabetika-welcome"
						target="_blank"
						description="Посмотреть инструкцию"
					>
						Навигация в сообществе
					</Cell>

					<Cell 
						before={
							<Avatar type="app" src={two} />
						} 
						href="https://vk.com/@dnevnik.diabetika-message"
						target="_blank"
						description="Размещайте сообщения в сообществе"
					>
						Предложить новость
					</Cell>

					<Cell 
						before={
							<Avatar type="app" src={tree} />
						} 
						href="https://vk.com/topic-116010319_39659115"
						target="_blank"
						description="Посмотреть объявления"
					>
						Взаимопомощь
					</Cell>
				</List>				
			</Group>
		);
	}
}

export default AdBlock;