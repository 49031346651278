import React from 'react';
import { Alert } from '@vkontakte/vkui';
import app from './app';


function showAlert(alert) {
	app.setState({
		popout: alert
	})
}

function closeAlert() {
	app.setState({
		popout: null
	})
}

export function showConfirmationAlert(title, description, callback) {
	showAlert(
		<Alert
			actions={[{
				title: 'Отмена',
				autoclose: true,
				style: 'cancel'
			}, {
				title: 'Хорошо',
				autoclose: true,
				style: 'destructive',
				action: () => {
					callback(true)
				}
			}]}
			onClose={closeAlert}
		>
			<h2>{title}</h2>
			<p>{description}</p> 
		</Alert>
	)
}