import React from 'react';
import { Panel, List, CellButton, Cell, PullToRefresh } from '@vkontakte/vkui';
import Header from '../components/Header/Header';
import AdBlock from '../components/AdBlock/AdBlock';

import app from '../func/app';
import navigation from '../func/navigation';
import { dynamicSort } from '../func/utils';
import { getData } from '../func/back';

class HomeComponents extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fetching: false,
		};
	}

	goCategorie = (e) => {
		const { cid } = e.currentTarget.dataset
		app.setState({
			target: +cid
		}, () => {
			navigation.go({activePanelComponents: 'Categorie'})
		})
	}

	get categories() {
		const { categories } = this.props
		return categories.sort(dynamicSort("sid"))
	}

	onRefresh = () => {
		this.setState({
			fetching: true
		}, () => {
			getData((data) => {
				const { categories, topics } = data	
				app.setState({
					categories:categories,
					topics:topics,
				}, () => {
					this.setState({
						fetching: false
					})
				})
			})
		})
	}

	render() {
		let { fetching } = this.state
		let { isAdmin } = this.props
		return (
			<Panel theme="white" id={this.props.id}>
				<Header name="Категории"/>
				<PullToRefresh onRefresh={this.onRefresh} isFetching={fetching}>
					<List>
						{
							(isAdmin) ? 
								<CellButton onClick={() => { navigation.go({activePanelComponents: 'Admin'}) }}>Админка</CellButton>
							: null
						}
						{
							this.categories.map((cat) => {
								const { name, id } = cat
								return (
									<Cell data-cid={id} key={id} onClick={this.goCategorie} expandable>{name}</Cell>
								)
							})
						}
						<AdBlock/>
					</List>
				</PullToRefresh>
			</Panel>
		)
	}
}

export default HomeComponents;