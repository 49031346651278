import React from 'react';
import { Panel, Div, Button, Gallery } from '@vkontakte/vkui';

import { startApp } from '../../func/init';

import OneM from '../../images/OneM.png';
import TwoM from '../../images/TwoM.png';
import TreeM from '../../images/TreeM.png';
import FourM from '../../images/FourM.png';

import './style.css';

class Welcome extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			slideIndex: 0,
			buttonWait: false,
		};
	}

	renderButton = (slideIndex) => {
		const { buttonWait } = this.state
		switch (slideIndex) {
			case 0:
				return (
					<Button
						size='l' 
						onClick={() => this.setState({slideIndex: slideIndex === 3 ? 3 : slideIndex + 1 })}
						disabled={buttonWait}
					>
						Хорошо
					</Button>
				)
			case 1:
				return (
					<Button
						size='l' 
						onClick={() => this.setState({slideIndex: slideIndex === 3 ? 3 : slideIndex + 1 })}
						disabled={buttonWait}
					>
						Далее
					</Button>
				)
			case 2:
				return (
					<Button
						size='l' 
						onClick={() => this.setState({slideIndex: slideIndex === 3 ? 3 : slideIndex + 1 })}
						disabled={buttonWait}
					>
						Отлично
					</Button>
				)
			case 3:
				return (
					<Button
						size='l' 
						onClick={this.startPril}
						disabled={buttonWait}
					>
						Вход
					</Button>
				)
			default:
				//
		}
	}

	startPril = () => {
		localStorage.setItem('welcome', 'true')
		startApp({goStartPanel: false})
	}

	render() {
		let { slideIndex } = this.state
		return (
			<Panel theme="white" id={this.props.id}>

					<Gallery
						slideWidth="100%"
						align="center"
						className="WelcomeGallery"
						slideIndex={slideIndex}
						onChange={slideIndex => this.setState({slideIndex})}
						autoplay={0}
						bullets={false}
					>

						<div className="WelcomeOneBlock">
							<div className="WelcomeTextBlock">
								<div className="WelcomeTitle">
									Участники размещают записи в сообществе, после модерации сообщение автора добавляется в очередь на публикацию.
								</div>
							</div>
							<div className="WelcomeImg">
								<img alt="" src={OneM}/>
							</div>
						</div>

						<div className="WelcomeOneBlock">
							<div className="WelcomeTextBlock">
								<div className="WelcomeTitle">
									Рекомендуем использовать поиск в сообществе, где можно выбрать интересующую категорию и найти все записи.
								</div>
							</div>
							<div className="WelcomeImg">
								<img alt="" src={TwoM}/>
							</div>
						</div>

						<div className="WelcomeOneBlock">
							<div className="WelcomeTextBlock">
								<div className="WelcomeTitle">
									Участники сообщества могут размещать объявления в обсуждениях, в которых хранятся сообщения по продаже, обмену или покупке.
								</div>
							</div>
							<div className="WelcomeImg">
								<img alt="" src={TreeM}/>
							</div>
						</div>

						<div className="WelcomeOneBlock">
							<div className="WelcomeTextBlock">
								<div className="WelcomeTitle">
									Размещайте сообщения, комментируйте авторов записей и пользуйтесь поиском по темам в сообществе!
								</div>
							</div>
							<div className="WelcomeImg">
								<img alt="" src={FourM}/>
							</div>
						</div>												

					</Gallery>
					<Div className="flex_center">
						{ 
							this.renderButton(slideIndex) 
						}
					</Div>

			</Panel>
		)
	}
}

export default Welcome;