/* Блок для вывода бейджей */
import React from 'react';
import './style.css';

class BadgeBlock extends React.Component {
	render() {
		let { children, color, backgroundColor, className, style } = this.props
		const myStyle = {
			color: color,
			backgroundColor: backgroundColor,
			...style
		}
		return (
			<div style={myStyle} className={`BadgeBlock ${className}`}>
				{children}
			</div>
		);
	}
}

export default BadgeBlock;