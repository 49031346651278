import React from 'react';
import { List, Cell, Div } from '@vkontakte/vkui';

import './style.css';

class CategorieList extends React.Component {
	render() {
		let { items, noTopics } = this.props
		if (noTopics) {
			return <Div className="flex_center">В этой категории пока ничего нет</Div> //в категории вообще нет тем
		} else {
			return (
				(items.length > 0) ? 
					<List>
						{
							items.map((topic) => {
								const { name, id, link } = topic
								return ( <Cell target="_blank" href={link.replace(/#/g,"%23")} key={id} expandable>{name}</Cell> )
							})
						}
					</List>
				: <Div className="flex_center">К сожалению, ничего не найдено</Div> //ничего не найдено
			);
		}
	}
}
export default CategorieList