import config from '../config';
import 'whatwg-fetch'

export function sentReq(json_data, callback = () => {}, callback_error = () => {}) {
	let url = config.backUrl
	json_data.params = config.params
	
	fetch(url, {
		method: 'post',
		headers: {
			'Accept': 'application/json, text/plain, */*',
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body: 'data=' + encodeURIComponent(JSON.stringify(json_data))
	})
	.then(function (response) {
		return response.json();
	})
	.then((data) => {
		callback(data);
	})
	.catch(function (error) {
		callback_error(error)
	});
}

export function sentWss(connection, json_data) {
	json_data.params = config.params
	connection.send(JSON.stringify(json_data));
}

export function getData(callback = () => {}) {
	sentReq({command: "getData"}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			const { text_error } = response
			console.error(text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

export function createCategorie(sid, name, callback = () => {}) {
	sentReq({command: "createCategorie", sid:sid, name:name}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			const { text_error } = response
			console.error(text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

export function delCategorie(id, callback = () => {}) {
	sentReq({command: "delCategorie", id:id}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			const { text_error } = response
			console.error(text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

export function editCategorie(id, sid, name, callback = () => {}) {
	sentReq({command: "editCategorie", id:id, sid:sid, name:name}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			const { text_error } = response
			console.error(text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

export function createTopic(sid, name, categories, link, callback = () => {}) {
	sentReq({command: "createTopic", sid:sid, name:name, categories:categories, link:link}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			const { text_error } = response
			console.error(text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

export function delTopic(id, callback = () => {}) {
	sentReq({command: "delTopic", id:id}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			const { text_error } = response
			console.error(text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

export function editTopic(id, sid, name, categories, link, callback = () => {}) {
	sentReq({command: "editTopic", id:id, sid:sid, name:name, categories:categories, link:link }, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			const { text_error } = response
			console.error(text_error)
		}
	}, (error) => {
		console.log(error)
	})
}