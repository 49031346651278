import React from 'react';
import { View, ScreenSpinner, ConfigProvider, Root } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

//хелперы
import app from './func/app';
import config from './config';

import { go } from './func/init';
import { devLog } from './func/utils';
import navigation from './func/navigation';

//стартовые панели
import HomeComponents from './panels/HomeComponents';

//Welcome Panel
import Welcome from './panels/Welcome/Welcome';

//HomeComponents
import Admin from './panels/Admin';
import CategorieEdit from './panels/CategorieEdit';
import TopicEdit from './panels/TopicEdit';
import Categorie from './panels/Categorie';
import CategorieAdd from './panels/CategorieAdd';
import TopicAdd from './panels/TopicAdd';

class App extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			scheme: "client_light", //тема оформления client_light, client_dark, bright_light, space_gray
			header: false, //показывает или скрывает хедер для отображения при кастомном хедере на десктопе
			userScope: [], //права пользователя
			isStartInGroup: false, //сервис запущен из группы
			isAdminInGroup: false, //пользователь администратор в сообществе
			goStartPanel: false, //показывать стартовый экран
			error: { //ошибка js
				show: false,
				type: null,
				message: null,
				error: null
			},
			//навигация
			navPanels: {
				activeStory: "components", //активный эпик вью
				activePanelComponents: "Home", //активная панель
			},
			activeItem: "categories", //навигация по вкладкам
			isLoad: false, //загрузка приложения
			popout: null, //всплывающие окна
			apiUsersData: [], //информация о пользователях из апи
			apiGroupsData: [], //информация о группах из апи
			categories: [
				{
					id:1,
					sid: 5,
					name: "Название категории1"
				},
				{
					id:2,
					sid: 4,
					name: "Название категории2"
				},
				{
					id:3,
					sid: 3,
					name: "Название категории3"
				},
				{
					id:4,
					sid: 9,
					name: "Название категории4"
				},
				{
					id:5,
					sid: 100,
					name: "Название категории5"
				}
			],
			topics: [
				{
					id:1,
					sid: 5,
					name: "тема1",
					categories: [1,2],
					link: "https://vk.com/wall-116010319?q=#Вождение",
				},
				{
					id:2,
					sid: 4,
					name: "тема2",
					categories: [1,3],
					link: "https://vk.com/wall-116010319?q=#Вождение",
				},
				{
					id:3,
					sid: 3,
					name: "тема3",
					categories: [1,2,3,4,5],
					link: "https://vk.com/wall-116010319?q=#Вождение",
				},
				{
					id:4,
					sid: 9,
					name: "тема4",
					categories: [1],
					link: "https://vk.com/wall-116010319?q=#Вождение",
				},
				{
					id:5,
					sid: 1,
					name: "тема5",
					categories: [1],
					link: "https://vk.com/wall-116010319?q=#Вождение",
				},
			],
			target: null,
			targetEdit: null,
		};
	}

	componentDidMount() {
		app.setState = (state, callback) => {
			this.setState(state, callback)
		}
		app.state = this.state
		go()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.data !== this.state) {
			app.state = {
				...this.state
			}
		}
	}

	onStoryChange = (e) => {
		navigation.setStory({ activeStory: e.currentTarget.dataset.story })
	}

	render() {
		devLog(this.state)
		let { isLoad, popout, navPanels, header, scheme, goStartPanel, categories, target, topics, targetEdit, activeItem } = this.state
		let { activeStory, activePanelComponents } = navPanels
		let { admin_app } = config
		if (isLoad) { //сервис уже получил все даннные и загружен
			if (goStartPanel) {
				return (
					<ConfigProvider scheme={scheme} webviewType="vkapps">
						<View header={false} id="components" popout={popout} activePanel={activePanelComponents}>
							<Welcome id="Home"/>
						</View>
					</ConfigProvider>
				)
			} else {
				return (
					<ConfigProvider scheme={scheme} webviewType="vkapps">
						<Root activeView={activeStory}>
							<View header={header} id="components" popout={popout} activePanel={activePanelComponents}>
								<HomeComponents 
									id="Home"
									categories={categories}
									isAdmin={admin_app.indexOf(+config.params.vk_user_id) !== -1}
								/>
								<Categorie 
									topics={topics}
									target={target}
									id="Categorie"
								/>
								<Admin 
									id="Admin"
									categories={categories}
									topics={topics}
									activeItem={activeItem}
								/>

								<CategorieAdd
									id="CategorieAdd"
								/>

								<TopicAdd
									id="TopicAdd"
									categories={categories}
								/>

								<CategorieEdit 
									id="CategorieEdit"
									categories={categories}
									targetEdit={targetEdit}
								/>

								<TopicEdit 
									id="TopicEdit"
									topics={topics}
									categories={categories}
									targetEdit={targetEdit}
								/>

							</View>
						</Root>
					</ConfigProvider>
				);
			}
		} else { // сервис ещё не получил все данные
			return <ScreenSpinner/>
		}
	}
}

export default App;